import React, { ReactElement } from "react";
import cn from "classnames";

import { CustomizedDialogs } from "../../Footer/Modal";
import { SuccessComponentProps } from "./types";
import { ReactComponent as Logo } from "../../../assets/logo-dark.svg";
import { ReactComponent as SuccessImage } from "../../../assets/success.svg";
import { ReactComponent as CheckEmailBackground } from "../../../assets/check-email-background.svg";

import styles from "./styles.mod.scss";
import StoreButton from "../../StoreButton";

const SuccessComponent = ({
  name,
  newEmail,
  changeEmail,
}: SuccessComponentProps): ReactElement => {
  const containerCn = cn("container", styles.container);

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <CheckEmailBackground height="100%" width="100%" />
      </div>
      <div className={containerCn}>
        <div className="row">
          <div className="col-12">
            <div className={styles.logoContainer}>
              <a className={styles.logoLink} href="/">
                <Logo />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          {changeEmail ? (
            <div className="col-12 col-lg-6">
              <h1 className={styles.title}>Neue E-Mail bestätigt!!</h1>
              <div className={styles.descriptionContainer}>
                <p>
                  Du hast deine E-Mail Adresse erfolgreich geändert und
                  bestätigt. Ab jetzt kannst dich unter <b>{newEmail}</b> in der
                  App einloggen.
                </p>
              </div>
            </div>
          ) : (
            <div className="col-12 col-lg-6">
              <h1 className={styles.title}>Willkommen bei stromee, {name}!</h1>
              <div className={styles.descriptionContainer}>
                <p>
                  Die Anmeldung hat geklappt, deine Daten haben wir auch. Super!
                  Jetzt sind wir dran. Wir melden uns schnellstmöglich mit
                  weiteren Infos bei dir.
                </p>
                <p>
                  Noch einfacher geht’s natürlich über unsere stromee App: Hier
                  gibt es alle Details zu deinen Daten, Tarif und
                  Vertragsstatus.
                </p>
                <p>Hier geht’s zum App download:</p>
              </div>
              <div className={styles.buttonsContainer}>
                <StoreButton
                  url="https://apps.apple.com/de/app/id1521872083"
                  target="_blank"
                  text="App Store"
                  className={styles.footer__storeButton}
                />
                <StoreButton
                  url="https://play.google.com/store/apps/details?id=com.stromee.android"
                  target="_blank"
                  text="Google Play"
                  className={styles.footer__storeButton}
                />
              </div>
            </div>
          )}
          <div className="col-12 col-lg-6 d-lg-flex align-items-lg-center justify-content-lg-end">
            <div className={styles.imageContainer}>
              <SuccessImage />
            </div>
          </div>
        </div>
        <div className="row flex-grow-1 align-items-end">
          <div className="col-12">
            <div className={styles.agrrementsContainer}>
              <CustomizedDialogs
                agreementLinkClassName={styles.agreementLink}
                withoutCopyright
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessComponent;
